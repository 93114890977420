import { Box, BoxProps, Center, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'
import { BiRightArrowAlt } from 'react-icons/bi'

export default function CTAButton(props: HTMLChakraProps<'button'>) {
  const { children, ...rest } = props as BoxProps
  return (
    <Center
      minW="142px"
      as="button"
      px="6"
      py="4"
      fontWeight="bold"
      transition="all 0.2s"
      rounded="lg"
      outline={0}
      bg="brand.500"
      _focus={{ shadow: 'outline' }}
      _active={{ transform: 'translateY(2px)' }}
      _hover={{ bg: 'brand.700' }}
      {...rest}
    >
      {children}
      <Box as={BiRightArrowAlt} ml="2" fontSize="lg" />
    </Center>
  )
}