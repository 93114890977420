import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

interface LogoProps extends HTMLChakraProps<'svg'> {
    iconColor?: string
}

export const AccountingAndTax = (props: LogoProps) => {
    const { ...rest } = props
    return (
        <chakra.svg
            width={12}
            height={12}
            viewBox="0 0 49 48"
            fill="none"
            {...rest}
        >
            <path
                d="M21.302 35.11c0 6.972 5.652 12.624 12.625 12.624s12.625-5.652 12.625-12.625S40.9 22.484 33.927 22.484 21.302 28.136 21.302 35.11zm7.694-4.931a1.693 1.693 0 011.188-.493 1.7 1.7 0 011.19.492c.312.313.49.747.49 1.189 0 .442-.178.875-.49 1.188a1.698 1.698 0 01-1.19.493c-.442 0-.876-.18-1.189-.493a1.693 1.693 0 01-.492-1.188c0-.442.18-.876.492-1.189zm9.862 9.86a1.694 1.694 0 01-1.189.493 1.683 1.683 0 01-1.188-2.87 1.693 1.693 0 011.188-.492c.443 0 .876.18 1.189.493.313.312.492.746.492 1.188 0 .444-.18.876-.492 1.188zm-.023-9.838a1.68 1.68 0 010 2.377l-7.44 7.44a1.676 1.676 0 01-1.188.492 1.68 1.68 0 01-1.189-2.87l7.44-7.44a1.681 1.681 0 012.377 0z"
                fill="#fff"
            />
            <path
                d="M17.94 35.11c0-8.816 7.172-15.987 15.987-15.987.292 0 .582.008.87.024V1.68A1.68 1.68 0 0033.118 0H4.462a1.68 1.68 0 00-1.68 1.68v44.64c0 .927.752 1.68 1.68 1.68h20.021c-3.964-2.912-6.543-7.606-6.543-12.89zM12.286 8.927h11.903a1.68 1.68 0 010 3.361H12.286a1.68 1.68 0 010-3.361zm0 8.927h11.903a1.68 1.68 0 010 3.362H12.286a1.68 1.68 0 010-3.361zm2.975 12.29h-2.975a1.68 1.68 0 010-3.362h2.975a1.68 1.68 0 010 3.361z"
                fill="#fff"
            />
        </chakra.svg>
    )
}