import { Box, Heading, SimpleGrid, Stack, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'
import CTAButton from 'pages/home/Testimonial/CTAButton'
import Feature from 'pages/home/Testimonial/Feature'
import { FinalcialPlanning } from 'components/icons/FinalcialPlanning'
import { Lending } from 'components/icons/Lending'
import { AccountingAndTax } from 'components/icons/AccountingAndTax'

export default function UseCase() {

  const onClickSignUp = () => {
    location.href = "https://app.walawong.com/auth/sign-up";
  }

  return (
    <Box as="section" bg={mode('#FFFFFF', 'gray.800')} id='use-case'>
      <Box bg="#3C3D35" color="white" pt="24" pb="10rem">
        <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
          <Stack
            spacing="10"
            direction={{ base: 'column', lg: 'row' }}
            align={{ base: 'flex-start', lg: 'center' }}
            justify="space-between"
          >
            <Heading
              size="2xl"
              lineHeight="short"
              fontWeight="extrabold"
              maxW={{ base: 'unset', lg: '800px' }}
            >
              What will you be building? Walawong makes it easier.
            </Heading>
            <CTAButton w={{ base: 'full', md: 'auto'}} onClick={onClickSignUp}>Sign Up</CTAButton>
          </Stack>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3 }}
            spacing={{ base: '12', md: '9', lg: '3' }}
            mt={{ base: '12', md: '20' }}
          >
            <Feature icon={<Lending />} title="Lending">
              Large amounts of cryptoassets are held in wallets or exchanges, completely underutilised.
              We help identify these assets for you to offer personalised opportunities to your users.
            </Feature>
            <Feature icon={<FinalcialPlanning />} title="Financial Planning">
              Your users now hold digital assets and cryptocurrencies as part of their growing portfolio.
              By integrating Walawong, you will be able to help them better understand their complete portfolio.
            </Feature>
            <Feature icon={<AccountingAndTax />} title="Accounting & Tax">
              Requiring customers to manually export CSVs is not the way to go.
              We handle the connections for you while also standardizing and formatting the data
              needed for tax calculations.
            </Feature>
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  )
}