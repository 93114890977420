import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

interface LogoProps extends HTMLChakraProps<'svg'> {
    iconColor?: string
}

export const FinalcialPlanning = (props: LogoProps) => {
    const { ...rest } = props
    return (
        <chakra.svg
            width={12}
            height={12}
            viewBox="0 0 48 48"
            fill="none"
            {...rest}
        >
            <path
                d="M41.397 9.571c0-.855-.868-1.104-1.544-1.255v2.486c.754-.051 1.544-.404 1.544-1.23zM39.1 4.274c-.855.024-1.408.528-1.408 1.067 0 .628.468.992 1.408 1.18V4.274z"
                fill="#fff"
            />
            <path
                d="M39.452 15.183c4.187 0 7.592-3.406 7.592-7.591C47.044 3.407 43.638 0 39.452 0c-4.189 0-7.595 3.406-7.595 7.592 0 4.185 3.406 7.591 7.595 7.591zm-3.339-9.554c0-1.705 1.419-2.587 2.987-2.636v-.668c0-.215.163-.428.375-.428.214 0 .378.213.378.428v.668c.977.026 2.988.64 2.988 1.872 0 .49-.366.777-.791.777-.819 0-.805-1.343-2.196-1.368V6.66c1.658.35 3.125.841 3.125 2.774 0 1.681-1.255 2.536-3.125 2.65v.776c0 .214-.164.428-.378.428-.212 0-.375-.214-.375-.428v-.776c-2.121-.053-3.175-1.32-3.175-2.313 0-.5.302-.79.777-.79 1.406 0 .314 1.732 2.4 1.82V8.178c-1.863-.339-2.99-1.154-2.99-2.548zM8.887 33.817H2.342a2.182 2.182 0 00-2.181 2.182v9.818a2.183 2.183 0 002.181 2.182h6.545a2.18 2.18 0 002.182-2.182v-9.818a2.18 2.18 0 00-2.182-2.182zM25.803 26.182h-6.545a2.182 2.182 0 00-2.181 2.182V45.82c0 1.207.977 2.182 2.181 2.182h6.545a2.181 2.181 0 002.183-2.182V28.36c0-1.202-.977-2.18-2.183-2.18zM42.723 17.454h-6.544a2.182 2.182 0 00-2.183 2.182v26.18c0 1.208.977 2.183 2.182 2.183h6.545a2.18 2.18 0 002.182-2.182v-26.18a2.181 2.181 0 00-2.182-2.183z"
                fill="#fff"
            />
        </chakra.svg>
    )
}