import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

interface LogoProps extends HTMLChakraProps<'svg'> {
    iconColor?: string
}

export const Lending = (props: LogoProps) => {
    const { ...rest } = props
    return (
        <chakra.svg
            width={12}
            height={12}
            viewBox="0 0 48 42"
            fill="none"
            {...rest}
        >
            <path
                d="M.5 27.8c.32-.012.639-.032.959-.032h7.363v13.667H1.053c-.183 0-.368.02-.547.03L.5 27.8zM47.47 41.436h-.498c-8.338 0-16.673.002-25.004.006-1.962 0-3.846-.43-5.727-.913-1.42-.365-2.843-.708-4.264-1.064-.117-.029-.231-.068-.368-.108v-5.34c0-2.019.014-4.038-.009-6.058 0-.382.136-.486.472-.608.912-.34 1.84-.673 2.682-1.155a8.65 8.65 0 0110.433 1.35.938.938 0 00.607.209c2.6.015 5.2 0 7.8.012 2.648.012 4.839 1.74 5.411 4.26.086.378.11.768.168 1.2H24.03v2.743h17.846c2.953 0 5.21 1.997 5.587 4.916.013.183.015.367.007.55zM33.684 15.486h-2.752c.09.454.129.889.266 1.29.383 1.119 1.138 1.919 2.207 2.435.134.064.303.243.304.365.02 1.774.014 3.55.014 5.326-4.62-.27-10.618-4.346-10.992-11.522C22.357 6.205 27.966 1.07 33.729.534v.433c0 1.565-.01 3.129.009 4.693 0 .33-.08.498-.4.647a4.101 4.101 0 00-1.905 1.823 4.058 4.058 0 00-.393 2.597c.16.885.61 1.693 1.28 2.298a4.13 4.13 0 002.425 1.05c.154.014.306.022.458.04.735.08 1.255.623 1.274 1.316.008.33-.105.65-.318.904-.213.253-.51.42-.838.472-.674.121-1.3-.273-1.527-.95-.038-.118-.065-.233-.11-.37z"
                fill="#fff"
            />
            <path
                d="M36.518 9.98h2.698c0-.933-.246-1.783-.85-2.446-.464-.504-1.08-.868-1.621-1.302-.104-.083-.259-.2-.259-.302-.016-1.79-.012-3.578-.012-5.368 4.566.263 10.47 4.276 10.995 11.393.472 6.416-4.537 12.362-10.97 12.978-.01-.13-.026-.26-.026-.389 0-1.609.008-3.218-.006-4.827 0-.287.092-.419.348-.54 1.795-.853 2.697-2.628 2.32-4.544-.352-1.81-1.917-3.13-3.854-3.254-1.036-.067-1.674-.708-1.551-1.563.043-.303.188-.582.41-.794.222-.212.51-.344.816-.375.314-.038.632.036.896.208.265.173.459.433.548.735.047.128.08.258.118.39z"
                fill="#fff"
            />
        </chakra.svg>
    )
}